import Vue from 'vue'
import Vuex from 'vuex'
import casino from './casino'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    casino
  }
})
