<template>
  <div class="home">
    <div class="container-fluid bg-casino">
<div id="content">
<div class="row">
<div class="col-6">
<img style="height: 80px" src="../assets/images/logo.png" alt="Logo" />
</div>
<div class="col-6"></div>
</div>
<div class="row p-0 m-0">
<div class="col-6">
<img style="height: 40px;cursor:pointer" src="../assets/images/ic_back_to_casino.png" alt="Ic back to casino" @click="backToHome()"  />
</div>
<div class="col-6">
</div>
</div>
<div class="container h100">
<div class="row">
<div class="col-0 col-sm-0 col-md-3 col-lg-4 p-0"></div>
<div class="col-12 col-sm-12 col-md-6 col-lg-4 p-0">
<img class="img-fluid w-100 animate__animated animate__zoomIn" :src="getLogoGame()" alt="Sa" />
</div>
<div class="col-0 col-sm-0 col-md-3 col-lg-4 p-0"></div>
</div>
<div class="row mt-3">
    <div v-for="(item, index) in games" class="col-12 col-sm-12 col-md-6 col-lg-3 p-0 animate__animated animate__backInUp" @click="goToGame(item)">
        <div id="room-percent-E01" class="room-percent-right">{{item.percent}}%</div>
        <div class="image-container m-3">
        <img class="w-100" :src="require(`../assets/images/${item.image}`)" />
        </div>
    </div>
</div>
</div>
</div>
</div>
<div class="modal fade" id="noCreditModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
<div class="modal-content">
<div class="modal-header">
<h5 class="modal-title" id="exampleModalLongTitle">ผิดพลาด</h5>
<button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
</div>
<div class="modal-body">
<h3>เวลาของคุณไม่เพียงพอ กรุณาติดต่อแอดมิน</h3>
</div>
<div class="modal-footer">
<button type="button" class="btn btn-primary" data-dismiss="modal">ปิด</button>
</div>
</div>
</div>
</div>
<div class="modal fade" id="formModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
<div class="modal-header">
<h5 class="modal-title" id="exampleModalLabel">กรุณากรอกรายละเอียด</h5>
<button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
</div>
<div class="modal-body">
<form>
<input type="hidden" id="selectedRoom" />
<div class="form-group">
<label for="funding" class="col-form-label">ใส่เงินทุน:</label>
<input type="number" id="funding" class="form-control" min="0" data-bind="value:replyNumber" />
</div>
<div class="form-group">
<label for="profit" class="col-form-label">ใส่กำไร:</label>
<input type="number" id="profit" class="form-control" min="0" data-bind="value:replyNumber" />
</div>
</form>
</div>
<div class="modal-footer">
<button type="button" class="btn btn-info" id="formModalSubmit">ข้าม</button>
<button type="button" class="btn btn-secondary" data-dismiss="modal">ยกเลิก</button>
<button type="button" class="btn btn-primary" id="formModalSubmit">ตกลง</button>
</div>
</div>
</div>
</div>
<div class="modal fade" id="loadingModal" tabindex="-1">
<div class="modal-dialog modal-sm modal-dialog-centered">
<div class="modal-content" style="background-color: transparent; border: 0">
<div class="modal-body" id="modalBody">
<div class="row">
<div class="col-12">
<div class="loader">Loading...</div>
</div>
</div>
<div class="row">
<div class="col-12">
<div class="white text-center">
<h1 id="loadingMessage">กำลังวิเคราะห์ผลห้อง 1 ...</h1>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
  </div>
</template>
<script>
export default{
  created(){
       this.game = this.$route.params.game;
       this.$store.dispatch("casino/getGames", this.game);
  },
  data() {
    return {
      game:'sa',
    }
  },
  computed: {
    games() {
      return this.$store.getters["casino/games"];
    }
  },
  methods: {
    backToHome(){
        this.$store.dispatch("casino/disconnect");
        this.$router.push('/home');
    },
    getLogoGame(){
        return require(`../assets/images/${this.game}.png`)
    },
    goToGame(item){
        this.$store.dispatch("casino/disconnect");
        $("#loadingMessage").text("กำลังวิเคราะห์ผลห้อง " + item.room + " ...");
        $('#loadingModal').modal('show');
        setTimeout(() => {
            $('#loadingModal').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            this.$router.push('/room/'+this.game+'/'+item.room);
        }, 3000);
    }
    
  }
}
</script>
