<template>
  <div>
	<!-- partial -->
<div id="demo"><canvas width="883" height="600"></canvas></div>
<div class="container h-100 p-0 d-flex justify-content-center">
	<div class="row my-auto w-100">
		<div class="offset-lg-3 col-lg-6 col-md-12 p-3">
			<div class="card card-custom">
				<div class="card-body">
					<div class="d-flex justify-content-center text-center">
						<div class="img-wrapper mx-auto">
							<img src="../assets/images/progressLogoAM.png" class='img-fluid'>
						</div>
					</div>
						<div class="form-group">
							<label class="label-custom d-flex justify-content-center">กรอกลิงค์เกม</label>
							<input type="text" class="form-control my-4 text-center" v-model="url" placeholder="ตัวอย่าง https://m.pg-demo.com/wttd-bondito/index.html">
						</div>
						<div class="" v-if="error">
							<label class="d-flex justify-content-center" style="-webkit-text-stroke-width: 1px;-webkit-text-stroke-color: #ff5733;-webkit-text-fill-color: #fff;">{{error}}</label>
						</div>
						<div class="btn-contain d-flex justify-content-center">
							<a type="submit" class="btn btn-success" @click="scan">
							เข้าเล่นเลย
							</a>
						</div>
				</div>
			</div>
		</div>
		<div class="offset-lg-3 col-lg-6 col-md-12 p-3">
			<div class="card card-custom-step">
				<div class="card-body">
					<p>วิธีใช้งาน Stark</p>
					<ul>
						<li style="font-size:15px;">1. กรอก Username & Password</li>
						<li style="font-size:15px;">2. กรอกลิ้งค์เกมที่จะเข้าเล่น</li>
						<li style="font-size:15px;">3. Stark จะคำนวณ % ในทุกๆ 10-20 วินาที</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
  </div>
</template>
<script>
export default{
  mounted(){
    this.bind('#demo', ()=>{
        return new Particleground.particle( '#demo', {
            num: 100
        });
    });
  },
  data() {
    return {
      url:'',
	  error:'',
    }
  },
  methods: {
    bind(id, run){
      var effect = run();
      var btnBox = document.querySelector(id).nextElementSibling;
    },
    scan(){
      if(this.url){
		const games = ['m.pgf-asqb7a.com','gamessea.kaga88.com','sg.sp-platform.com','w1.bluecave88.com','frerpt.kathilag.com','static-prod-tw.dcgames.fun','static.shycfjwz.com','api.ayitang.com','gamessea.kaga88.com','cors.mannagaming.com','629xyss.fafafa3388.com','purple.romadeluxe.com','booongo2-dgm.gv-gamespace.com','lobby-ahvgl62wwq-df.a.run.app','wbgame.sssjlsss.com','gamelaunch.wazdan.com','wsx.mayousa.com','funky.ttstations.com','lobby.argonplay.com','games.ambslot.com','nttech-sg0.ppgames.net','game.3yjt.com','m.pgjazz.com','lobby.silverkirinplay.com','egame.multi888.com','play.pgslot.co','www.gwc688.net']
        const url = this.url.split('/');
        console.log(url);
        if((url[0]==='https:' || url[0]==='http:') && games.includes(url[2])){
			if(url[0]==='http:'){
				this.url = this.url.replace('http:','https:');
			}
			localStorage.setItem("url", this.url);
          this.$router.push('/room');
        }else{
			this.error = 'ระบบ AI ไม่สามารถ scan ลืงค์เกมส์ของท่านได้';
		}
      }
    },
  }
}
</script>
<style scoped>
@font-face {
		font-family: Bungee;
		src: url("../assets/Bungee-Regular.ttf");
	}

	@font-face {
		font-family: Kanit;
		src: url("../assets/Kanit-Bold.ttf");
	}

	*{
		font-family: Kanit;
	}
	h1 { margin-top:150px;}
			[id^="demo"]{
					width: 100%;
					height: 1000px;
					position: absolute;
			}
</style>