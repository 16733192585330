<template>
  <div>
	<!-- partial -->
<div id="demo"><canvas width="883" height="600"></canvas></div>
<div class="container h-100 p-0 d-flex justify-content-center">
	<div class="row my-auto w-100">
		<div class="offset-lg-3 col-lg-6 col-md-12 p-3">
			<div class="card card-custom">
				<div class="card-body">
					<div class="d-flex justify-content-center text-center">
						<div class="img-wrapper mx-auto">
							<img src="../assets/images/progressLogoAM.png" class='img-fluid'>
						</div>
					</div>
					<form action="#" id="checkCode" method="post">
						<div class="form-group">
							<label class="label-custom d-flex justify-content-center">Login</label>
							<input autofocus="autofocus" type="text" class="form-control my-4 text-center" v-model="username" placeholder="Username">
              				<input type="password" autocomplete="off" class="form-control my-4 text-center" v-model="password" placeholder="Password">
						</div>
						<div class="btn-contain d-flex justify-content-center">
							<a type="submit" class="btn btn-success" href="#" @click="login">
							เข้าสู่ระบบ
							</a>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="offset-lg-3 col-lg-6 col-md-12 p-3">
			<div class="card card-custom-step">
				<div class="card-body">
					<p>วิธีใช้งาน Stark</p>
					<ul>
						<li style="font-size:15px;">1. กรอก Username & Password</li>
						<li style="font-size:15px;">2. กรอกลิ้งค์เกมที่จะเข้าเล่น</li>
						<li style="font-size:15px;">3. Stark จะคำนวณ % ในทุกๆ 10-20 วินาที</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
  </div>
</template>
<script>
import firebase, { database } from "firebase/app"
import "firebase/firestore"
import "firebase/database"
import 'firebase/auth'
import moment from "moment-timezone"
export default{
  mounted(){
    this.bind('#demo', ()=>{
            return new Particleground.particle( '#demo', {
                num: 100
            });
    });
  },
  data() {
    return {
      username:'',
      password:'',
    }
  },
  methods: {
    bind(id, run){
      var effect = run();
      var btnBox = document.querySelector(id).nextElementSibling;
    },
    async getUserId() {
      const email = firebase.auth().currentUser.email.split('@')
      const users = (await firebase.database().ref('/users2').orderByChild('username').equalTo(email[0]).once('value')).val()
      const user = users[Object.keys(users)[0]]
      const key = Object.keys(users)[0]
      if (user.status === 'Y') {
        firebase.database().ref(`/users2/${key}`).update({
          lastlogin: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
        }).then(()=>{
          this.$router.replace('/home');
        });
      }else{
        alert('โปรดตรวจสอบ username&password')
      }
    },
    login() {
      if (this.username && this.password) {
        firebase.auth().signInWithEmailAndPassword(this.username.trim().toLowerCase()+'@stark.com', this.password.trim()).then((res) => {
          this.getUserId()
        }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alert('โปรดตรวจสอบ username&password')
        })
      }else{
        alert('โปรดตรวจสอบ username&password')
      }
    },
  }
}
</script>
<style scoped>
@font-face {
		font-family: Bungee;
		src: url("../assets/Bungee-Regular.ttf");
	}

	@font-face {
		font-family: Kanit;
		src: url("../assets/Kanit-Bold.ttf");
	}

	*{
		font-family: Kanit;
	}
	h1 { margin-top:150px;}
			[id^="demo"]{
					width: 100%;
					height: 1000px;
					position: absolute;
			}
</style>