import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/auth'

const configFirebase = {
  apiKey: 'AIzaSyBnS3LbFHyo9qUgiX3VdN0EW3U5gKjskwo',
  authDomain: 'king-bar.firebaseapp.com',
  databaseURL: 'https://king-bar-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'king-bar',
  storageBucket: 'king-bar.appspot.com',
  messagingSenderId: '478492131995',
  appId: '1:478492131995:web:91a8b9e9cb095ab5163c3e'
  // apiKey: "AIzaSyCoJjHmavOaGYQ7VAync6OLztrx74AjVE4",
  // authDomain: "king-bar2.firebaseapp.com",
  // databaseURL: "https://king-bar2-default-rtdb.asia-southeast1.firebasedatabase.app",
  // projectId: "king-bar2",
  // storageBucket: "king-bar2.appspot.com",
  // messagingSenderId: "315274448875",
  // appId: "1:315274448875:web:1e1943f19e92f3f9e813ae"
}
firebase.initializeApp(configFirebase)
Vue.config.productionTip = false

let app: any
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
